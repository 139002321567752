<template>
	<v-row>

		<template v-if="permissaoDashboard">
			<DialogDashboards :dialog="dialog" />
			<v-skeleton-loader v-if="carregando" class="position-center" width="100vw" height="50vh" elevation="12"
				type="table-heading, list-item-two-line, image, table-tfoot" />
			<template v-else>
				<!-- FILTROS -->
				<v-col cols="12" md="12">
					<v-card>
						<v-card-text>
							<v-form>
								<v-row v-if="filtroCompleto.indexOf(usuario.idgrupo) != -1"
									:class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'">
									<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
										<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
											label="Data inicial" :outlined="true" :dense="true" />
									</v-col>
									<v-col class="d-none d-md-block px-1">
										<InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
											:outlined="true" :dense="true" />
									</v-col>
									<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
										<v-sheet class="text-center" height="100%">
											<v-row class="pa-3">
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
														label="Data inicial" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtfim"
														label="Data final" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="sheet = !sheet">Cancelar</v-btn>
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="get(), carrega(), sheet = !sheet">Filtrar</v-btn>
												</v-col>
											</v-row>
										</v-sheet>
									</v-bottom-sheet>
									<v-col cols="12" md="1" class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
										<v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"
											fab x-small @click="carrega()">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<v-row v-else-if="filtroComFilial.indexOf(usuario.idgrupo) != -1"
									:class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'">
									<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
										<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
											label="Data inicial" :outlined="true" :dense="true" />
									</v-col>
									<v-col class="d-none d-md-block px-1">
										<InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
											:outlined="true" :dense="true" />
									</v-col>
									<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
										<v-sheet class="text-center" height="100%">
											<v-row class="pa-3">
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
														label="Data inicial" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6" class="px-2">
													<InputDatePicker :disabled="carregando" v-model="busca.dtfim"
														label="Data final" :outlined="true" :dense="true" />
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="sheet = !sheet">Cancelar</v-btn>
												</v-col>
												<v-col cols="6">
													<v-btn text class="mt-n3" color="primary"
														@click="carrega(), sheet = !sheet">Filtrar</v-btn>
												</v-col>
											</v-row>
										</v-sheet>
									</v-bottom-sheet>
									<v-col class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
										<v-autocomplete :items="filiais.lista" clearable item-text="filial"
											item-value="idfilial" label="Filial" outlined dense :disabled="carregando"
											v-model="busca.idfiliais" multiple class="d-inline-flex d-md-none"
											append-outer-icon="mdi-menu" @click:append-outer="sheet = !sheet">
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span v-if="index === 1" class="grey--text text-caption ml-1">(+{{
													busca.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
										<v-autocomplete :items="filiais.lista" clearable item-text="filial"
											item-value="idfilial" label="Filial" outlined dense :disabled="carregando"
											v-model="busca.idfiliais" multiple class="d-none d-md-block">
											<template v-slot:selection="{ item, index }">
												<template v-if="index === 0">{{ item.filial }}</template>
												<span v-if="index === 1" class="grey--text text-caption ml-1">(+{{
													busca.idfiliais.length - 1 }})</span>
											</template>
										</v-autocomplete>
										<v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"
											fab x-small @click="carrega()">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</v-col>
								</v-row>
								<v-row v-else :class="dashboard.dtultimaatualizacao ? 'mb-n7' : 'mb-n4'">
									<v-spacer />
									<v-col cols="6" class="pl-2 pr-1">
										<InputDatePicker :disabled="carregando" v-model="busca.dtinicio"
											label="Data inicial" :outlined="true" :dense="true" />
									</v-col>
									<v-spacer />
									<v-col cols="6" md="6" class="px-1 d-flex">
										<InputDatePicker :disabled="carregando" v-model="busca.dtfim" label="Data final"
											:outlined="true" :dense="true" />
										<v-btn :disabled="carregando" class="ml-2 mt-1" color="primary" elevation="0"
											fab x-small @click="carrega()">
											<v-icon>mdi-magnify</v-icon>
										</v-btn>
									</v-col>
									<v-spacer />
								</v-row>
							</v-form>
						</v-card-text>
						<v-card-actions class="px-4 my-0 py-0">
							<small v-if="autoRefresh && !carregando" class="text--secondary text-caption">Próxima
								atualização em:
								{{ tempoRefresh }} segundos</small>
							<v-spacer />
							<span class="d-flex mb-n3 my-4">
								<v-switch :loading="carregando" v-model="autoRefresh" label="Atualização automática"
									color="primary" class="my-0 py-0"></v-switch>
							</span>
						</v-card-actions>
						<span v-if="dashboard.dtultimaatualizacao" class="text-caption text--disabled pl-3">* Atualizado
							em:
							{{
								dashboard.dtultimaatualizacao }}</span>
					</v-card>
				</v-col>
				<!-- CARDS -->
				<v-col cols="12" md="12">
					<!-- INDICES MAQUINA DE VENDAS -->
					<v-card :style="{ background: 'linear-gradient(to right, #1E3A5F, #004D66)' }" dark>
						<v-card-title class="d-flex justify-center white--text text-h5 font-weight-bold">
							Índices Máquina de Vendas
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text>
							<v-row class="pa-2">
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :title="`Venda do dia ${dia}`"
										:color="'#3D7AA8'">
										<template v-if="totaisMaquinaVendas.MAQUINA_DE_VENDAS.TOTAL_VENDA_DIARIA">{{ totaisMaquinaVendas.MAQUINA_DE_VENDAS.TOTAL_VENDA_DIARIA | formataDinheiro
											}}</template>
										<template v-else>{{ 0 | formataDinheiro }}</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta Diária Atingida (%)" :color="'#3D7AA8'">
										<template v-if="totaisMaquinaVendas.MAQUINA_DE_VENDAS.ATINGIMENTO_DIARIO">{{
                        totaisMaquinaVendas.MAQUINA_DE_VENDAS.ATINGIMENTO_DIARIO }} %</template>
										<template v-else>0.00 %</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" :title="`Margem do dia ${dia}`"
										:color="'#3D7AA8'">
										<template v-if="totaisMaquinaVendas.MAQUINA_DE_VENDAS.MARGEM_DIARIA">{{ totaisMaquinaVendas.MAQUINA_DE_VENDAS.MARGEM_DIARIA
											}}
											%</template>
										<template v-else>0.00 %</template>
										<MenuInfo texto="Margem de venda com base no apoio a decisão" />
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Venda acumulada" :color="'#3D7AA8'">
										<template v-if="totaisMaquinaVendas.MAQUINA_DE_VENDAS.TOTAL_VENDA_ACUMULADA">{{ totaisMaquinaVendas.MAQUINA_DE_VENDAS.TOTAL_VENDA_ACUMULADA | formataDinheiro
											}}</template>
										<template v-else>{{ 0 | formataDinheiro }}</template>
									</CardDashboard>
								</v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta Acumulada Atingida (%)" :color="'#3D7AA8'">
										<template v-if="totaisMaquinaVendas.MAQUINA_DE_VENDAS.ATINGIMENTO_ACUMULADO">{{
                        totaisMaquinaVendas.MAQUINA_DE_VENDAS.ATINGIMENTO_ACUMULADO }} %</template>
										<template v-else>0.00 %</template>
									</CardDashboard>
								</v-col>
                <v-col class="pa-1">
                  <CardDashboard :loading="carregando" title="Margem acumulada" :color="'#3D7AA8'">
                    <template v-if="totaisMaquinaVendas.MAQUINA_DE_VENDAS.MARGEM_ACUMULADA">{{ totaisMaquinaVendas.MAQUINA_DE_VENDAS.MARGEM_ACUMULADA }}
                      %</template>
                    <template v-else>0.00 %</template>
                    <MenuInfo texto="Margem de venda com base no apoio a decisão" />
                  </CardDashboard>
                </v-col>
								<v-col class="pa-1">
									<CardDashboard :loading="carregando" title="Meta do mês" :color="'#3D7AA8'">
										<template v-if="totaisMaquinaVendas">{{ metaMaquinaVendas || 0 | formataDinheiro
											}}</template>
										<template v-else>Indefinida</template>
									</CardDashboard>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>

          <!-- INDICES ECOMMERCE -->
          <v-card class="my-3" :style="{ background: 'linear-gradient(to right, #1E3A5F, #004D66)' }" dark>
            <v-card-title class="d-flex justify-center white--text text-h5 font-weight-bold">
              Índices Ecommerce
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="pa-2">
                <v-col class="pa-1">
                  <CardDashboard :loading="carregando" title="Venda acumulada" :color="'#3D7AA8'">
                    <template v-if="totaisEcommerce.TOTAL_VENDA">{{ totaisEcommerce.TOTAL_VENDA | formataDinheiro
                      }}</template>
                    <template v-else>{{ 0 | formataDinheiro }}</template>
                  </CardDashboard>
                </v-col>
                <v-col class="pa-1">
                  <CardDashboard :loading="carregando" title="Margem acumulada" :color="'#3D7AA8'">
                    <template v-if="totaisEcommerce.MARGEM">{{ totaisEcommerce.MARGEM }}
                      %</template>
                    <template v-else>0.00 %</template>
                    <MenuInfo texto="Margem de venda com base no apoio a decisão" />
                  </CardDashboard>
                </v-col>
                <v-col class="pa-1">
                  <CardDashboard :loading="carregando" title="Meta do mês" :color="'#3D7AA8'">
                    <template v-if="totaisEcommerce">{{ metaEcommerce || 0 | formataDinheiro
                      }}</template>
                    <template v-else>Indefinida</template>
                  </CardDashboard>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

				</v-col>
				<!-- GRAFICOS-->

        <!-- GRAFICO VENDA POR VENDEDOR -->
        <v-col cols="12" md="'12'">
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title class="col-12">
							<span class="text-truncate">Meta x Venda realizada por vendedor</span>
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
							<v-spacer />
							<v-btn :disabled="carregando" class="px-2 mb-1 ml-auto mt-2 mr-2" color="primary"
								elevation="0" small @click="abrirDialog(
									vendedoresMaquinaVendas,
									headerVendedores,
									'Meta x Venda realizada por vendedor'
								)">
								<v-icon size="18">mdi-magnify-plus-outline</v-icon>
								<span class="d-none d-md-block">Ver mais</span>
							</v-btn>
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300"
								type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
              <GraficoLineVendasOnline v-if="vendedoresMaquinaVendas" :dataSeries="[
                { name: 'Meta', type: 'column', data: dadosMeta },
                { name: 'Realizado', type: 'column', data: dadosRealizado },
                { name: 'Atingido', type: 'line', data: dadosAtingimento }
            ]"
            :labels="labelsNome" :titlesY="[
              { show: false, min: 0, max: 150000 },
              { show: false, min: 0, max: 150000 },
              { show: false, min: 0, max: 150000 / 1000 }
            ]"
            :tooltipsY="[
                { enabled: true, formatter: (val) => parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) },
                { enabled: true, formatter: (val) => parseFloat(val).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) },
                { enabled: true, formatter: (val) => `${val} %` }
            ]"
             :height="300" :porcentagem="false" :money="false" :stroke="{ curve: 'smooth', width: [0, 0, 6] }" />
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>


			</template>
		</template>
		<template v-else>
			<v-col>
				<v-card class="pa-5" color="primary" dark>
					<v-card-title class="mb-1">Bem-vindo</v-card-title>
					<v-card-text class="text-body-1">Para navegar pelo sistema selecione uma opção no menu
						acima</v-card-text>
				</v-card>
			</v-col>
		</template>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import MenuInfo from "../Widgets/MenuInfo";
import DialogDashboards from "../Widgets/DialogDashboards";
import GraficoLineVendasOnline from "@/Components/Widgets/GraficoLineVendasOnline.vue";

export default {
	name: "DashboardVendas",
	components: {
		InputDatePicker,
		CardDashboard,
		MenuInfo,
		DialogDashboards,
    GraficoLineVendasOnline,
	},
	data: () => ({
		tempoRefresh: 60,
		autoRefresh: false,
		usuariosAutorizados: [1, 4, 5, 6, 8, 20],
		filtroCompleto: [1, 4, 5, 8, 20],
		filtroComFilial: [1, 4, 5, 8, 20],
		sheet: false,
		dialog: { status: false, dados: [], header: {} },
		carregando: false,
		carregando2: false,
		carregando3: false,

    totaisMaquinaVendas: [],
    dadosVendedores: [],
    vendedoresMaquinaVendas: [],
    vendedoresTotalVenda: null,
    vendedoresTotalMargem: null,
    totaisEcommerce: [],
    dadosMeta: [],
    metaEcommerce: null,
    metaMaquinaVendas: null,
    dadosEco: [],


    headerVendedores:{
      ID: { nome: "ID", tipo: "texto" },
      NOME: { nome: "Vendedor", tipo: "texto" },
      META: { nome: "Meta", tipo: "dinheiro" },
      TOTALVENDA: { nome: "Realizado", tipo: "dinheiro" },
      ATINGIMENTO: { nome: "Atingido", tipo: "porcentagem" },
      MARGEM: { nome: "Margem", tipo: "porcentagem" },
    },
    dadosMetaMaquina: [],
    dadosRealizado: [],
    dadosAtingimento: [],
    labelsNome:[],

		dialogOrcamentos: false,
		dialogPedidos: false,
		dialogDetalheMeta: false,
		expanded: [],
		expanded2: [],
		filiais: [],
		regionais: [],
		dashboard: {},
		ranking: [],
		rankingFat: [],
		metaDiariaAcumulada: 0,
		detalheMetaDiariaAcumulada: [],
		perAcumuladoDiario: 0,
		perDiario: 0,
		dia: `${new Date().toLocaleDateString("pt-BR")}`,
		busca: {
			dtinicio: `${new Date().getFullYear()}-${(new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getMonth() +
				1 <
				10
				? "0"
				: "") +
				(new Date().getMonth() + 1)
				}-01`,
			dtfim: new Date().toISOString().split("T")[0],
			idfiliais: [],
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		permissaoDashboard() {
			if (this.usuariosAutorizados.indexOf(this.usuario.idgrupo) != -1) {
				return true;
			} else {
				return false;
			}
		},
		corRealizadoDiario() {
			return this.realizadoCor(parseFloat(this.rankingFat.totalrealizadodiarioperc.toFixed(2)));
		},
		corRealizadoAcumulado() {
			return this.realizadoCor(parseFloat(this.rankingFat.totalrealizadoacumuladoperc.toFixed(2)));
		},
		corMargemDiaria() {
			return this.margemCor(parseFloat(this.rankingFat.totalmargemdiaria.toFixed(2)));
		},
		corMargemAcumulado() {
			return this.margemCor(parseFloat(this.rankingFat.totalmargemacumulada.toFixed(2)));
		},
	},
	methods: {
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 6 &&
							v.idfilial !== 12 &&
							v.idfilial !== 22 &&
							v.idfilial !== 25 &&
							v.idfilial !== 29 &&
							v.idfilial !== 32 &&
							v.idfilial !== 33 &&
							v.idfilial !== 34 &&
							v.idfilial !== 37
						);
					});
				});
		},
		async carrega() {
      await this.listaVendasMaquinaVendas();
      await this.listaAcumuladoMaquinaVendas();
      await this.listaVendasEcommerce();
		},
    listaMetas(){
      this.dadosMetaMaquina = [];

      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/vendas/maquinadevendas/meta`, {
            dtini: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
          })
          .then((res) => {
            let dados = res.data;

            // Se os dados vierem como string, tentar converter
            if (typeof res.data === 'string') {
              try {
                dados = JSON.parse(res.data);
              } catch (error) {
                console.error('Erro ao converter JSON:', error);
                return;
              }
            }
            this.dadosMetaMaquina = dados || [];

            // Guardando os valores nas variáveis
            this.metaMaquinaVendas = dados["9"].META || 0;
            this.metaEcommerce = dados["10"].META || 0;


            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
            this.dadosMetaMaquina = [];
          });
    },
    listaVendasEcommerce(){
      this.totaisEcommerce = [];

      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/vendas/maquinadevendas`, {
            dtini: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
            iddepartamento: 10,
          })
          .then((res) => {
            this.dadosEco = JSON.parse(res.data) || [];
            this.totaisEcommerce.TOTAL_VENDA = this.dadosEco["ECOMMERCE"].TOTAL_VENDA;
            this.totaisEcommerce.MARGEM = this.dadosEco["ECOMMERCE"].MARGEM;
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
            this.totaisEcommerce = [];
          });
    },
    listaVendasMaquinaVendas(){
      this.vendedoresMaquinaVendas = [];
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/vendas/maquinadevendas`, {
            dtini: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
            iddepartamento: 9,
          })
          .then((res) => {
            this.dadosVendedores = JSON.parse(res.data) || [];
            this.vendedoresTotalVenda = this.dadosVendedores.MAQUINA_DE_VENDAS.TOTAL_VENDA;
            this.vendedoresTotalMargem = this.dadosVendedores.MAQUINA_DE_VENDAS.MARGEM;
            this.vendedoresMaquinaVendas = this.dadosVendedores.MAQUINA_DE_VENDAS;
            delete this.vendedoresMaquinaVendas.TOTAL_VENDA;
            delete this.vendedoresMaquinaVendas.MARGEM;
            const ajustadoArray = Object.entries(this.vendedoresMaquinaVendas).map(([id, dados]) => ({
              ID: id,
              ...dados
            }));
            this.vendedoresMaquinaVendas = ajustadoArray;

            this.dadosMeta = this.vendedoresMaquinaVendas.map(v => parseFloat(v.META));
            this.dadosRealizado = this.vendedoresMaquinaVendas.map(v => parseFloat(v.TOTALVENDA));
            this.dadosAtingimento = this.vendedoresMaquinaVendas.map(v => parseFloat(v.ATINGIMENTO));
            this.labelsNome = this.vendedoresMaquinaVendas.map(v => String(v.NOME.split(' ')[0]))

            console.log('lista VENDEDORES: ', this.vendedoresMaquinaVendas);
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
            this.vendedoresMaquinaVendas = [];
          });
    },
    listaAcumuladoMaquinaVendas(){
      this.totaisMaquinaVendas = [];
      this.carregando = true;
      return axios
          .post(`${this.backendUrl}dashboard/vendas/maquinadevendasacumualdo`, {
            dtini: this.busca.dtinicio,
            dtfim: this.busca.dtfim,
            iddepartamento: 9,
          })
          .then((res) => {
            this.totaisMaquinaVendas = JSON.parse(res.data) || [];
            console.log('lista VENDAS: ', this.totaisMaquinaVendas);
            this.carregando = false;
          })
          .catch(() => {
            this.carregando = false;
            this.totaisMaquinaVendas = [];
          });
    },
		timerRefresh() {
			if (this.tempoRefresh < 0) {
				this.tempoRefresh = 60;
			}
			setTimeout(() => {
				this.tempoRefresh--;
				this.timerRefresh();
			}, 1000);
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
		margemCor(valor) {
			if (valor <= 20.50) {
				return 'red darken-3';
			} else if (valor >= 20.51 && valor <= 24.50) {
				return 'orange darken-2';
			}
			else if (valor >= 24.51) {
				return 'green darken-3';
			}
		},
		realizadoCor(valor) {
			if (valor <= 60.00) {
				return 'red darken-3';
			} else if (valor >= 60.01 && valor <= 90.50) {
				return 'orange darken-2';
			}
			else if (valor >= 90.51) {
				return 'green darken-3';
			}
		},
		async init() {
			await this.listarEmpresas();
			//await this.get();
      this.listaVendasMaquinaVendas();
      this.listaAcumuladoMaquinaVendas();
      this.listaVendasEcommerce();
      this.listaMetas();
			this.timerRefresh();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
		autoRefresh: function () {
			if (this.autoRefresh) {
				this.tempoRefresh = 60;
        this.listaVendasMaquinaVendas();
        this.listaAcumuladoMaquinaVendas()
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
.table {
	margin: 0px 0px 10px 0px;
}

.header th {
	background-color: #CFD8DC !important;
	height: 25px !important;
}

.body td {
	height: 20px !important;
	font-size: larger;
}

.bold-text {
	font-weight: bold;
}

.foot td {
	background-color: #CFD8DC !important;
	height: 20px !important;
}
</style>