<template>
  <VueApexCharts type="line" width="100%" :height="height" :options="chartOptions" :series="series"></VueApexCharts>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "GraficoLineColumnDashboard",
  components: { VueApexCharts },
  props: [
    "dataSeries",
    "labels",
    "titlesY",
    "tooltipsY",
    "height",
    "porcentagem",
    "money",
    "colors",
    "stroke"
  ],
  data: () => ({
    series: [],
    chartOptions: {
      chart: {
        type: "line",
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
      },
      responsive: [
        {
          breakpoint: 800,
          options: {
            yaxis: {
              show: false,
              forceNiceScale: true,
            },
            xaxis: {
              labels: {
                trim: true,
                hideOverlappingLabels: true,
              },
            },
            dataLabels: {
              enabled: true,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          borderRadius: 5,
          columnWidth: "90%",
          endingShape: "rounded",
          dataLabels: {
            position: "center",
            orientation: "horizontal",
          },
        },
      },
      colors: ["#002D56", "#F2B90F", "#951015"],
      stroke: {
        curve: "smooth",
        width: [0, 0, 6],
      },
      legend: {
        show: false,
      },
      markers: {
        size: 3,
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 0.75,
          opacityFrom: 0.9,
          opacityTo: 0.95,
          stops: [0, 7, 100],
        },
      },
      dataLabels: {
        enabled: false,
      },
      labels: [], // Labels do eixo X
      yaxis: [], // Títulos do eixo Y
      xaxis: {
        labels: {
          trim: true,
          hideOverlappingLabels: false,
        },
      },
      tooltip: {
        y: [],
      },
    },
  }),
  watch: {
    dataSeries(newData) {
      this.series = newData;
    },
    labels: {
      handler(newLabels) {
        this.chartOptions = {
          ...this.chartOptions,
          xaxis: {
            ...this.chartOptions.xaxis,
            categories: newLabels, // Atualiza corretamente as categorias do eixo X
          },
        };
      },
      immediate: true, // Garante que rode na inicialização
    },
    titlesY(newTitlesY) {
      this.chartOptions.yaxis = newTitlesY;
    },
    tooltipsY(newTooltipsY) {
      this.chartOptions.tooltip.y = newTooltipsY;
    },
    colors(newColors) {
      this.chartOptions.colors = newColors;
    },
    stroke(newStroke) {
      this.chartOptions.stroke = newStroke;
    }
  },
  created() {
    this.series = this.dataSeries;
    this.chartOptions.labels = this.labels;
    this.chartOptions.yaxis = this.titlesY;
    this.chartOptions.tooltip.y = this.tooltipsY;
    this.chartOptions.stroke = this.stroke;
    if (this.colors) {
      this.chartOptions.colors = this.colors;
    }
    if (this.porcentagem) {
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: function (val) {
          return `${parseFloat(val).toFixed(0)}%`;
        },
        style: {
          colors: ["#002D56", "#6d5002", "#951015"],
          fontSize: "11px",
          fontWeight: "bold",
        },
      };
    } else if (this.money) {
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: function (val) {
          return parseFloat(val).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
          });
        },
        style: {
          colors: ["#002D56", "#6d5002", "#951015"],
          fontSize: "11px",
          fontWeight: "bold",
        },
      };
    } else {
      this.chartOptions.dataLabels = {
        enabled: true,
        formatter: function (val) {
          return parseFloat(val).toFixed(2);
        },
        style: {
          colors: ["#002D56", "#6d5002", "#951015"],
          fontSize: "11px",
          fontWeight: "bold",
        },
      };
    }
    if (this.money && this.porcentagem) {
      this.chartOptions.dataLabels = {
        enabled: false,
      };
    }
  },

};
</script>

<style>
.apexcharts-tooltip {
  background: #ffffff !important;
  color: #023449;
  border-color: #023449 !important;
}

.apexcharts-tooltip-title {
  background-color: #023449 !important;
  color: #ffffff;
}
</style>
